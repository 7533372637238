
const VideoPromocional2 = ({ linkVideo, linkVideo2, linkVideo3 }) => {

    return (
        <div className="w-[100%] h-auto flex justify-center my-10 -mt-10">
            <div className="flex md:flex-row flex-col md:space-x-5 md:space-y-0 space-y-5 space-x-0 ">
                {
                    <iframe
                        title="Promotional video"
                        className='w-[90%] h-[280px] md:h-[360px] md:w-[500px] lg:h-[280px] lg:w-[450px]  rounded-3xl relative top-[80px] md:top-[150px]'
                        id="ytplayer"
                        type="text/html"
                        src={`https://www.youtube.com/embed/${linkVideo}?&autoplay=1&loop=1&rel=0&showinfo=0&color=white&iv_load_policy=3&playlist=${linkVideo}`}
                        frameborder="0" allowfullscreen
                    />
                }
                {
                    <iframe
                        title="Promotional video"
                        className='w-[90%] h-[280px] md:h-[360px] md:w-[500px] lg:h-[280px] lg:w-[450px] rounded-3xl relative top-[80px] md:top-[150px]'
                        id="ytplayer"
                        type="text/html"
                        src={`https://www.youtube.com/embed/${linkVideo2}?&autoplay=1&loop=1&rel=0&showinfo=0&color=white&iv_load_policy=3&playlist=${linkVideo2}`}
                        frameborder="0" allowfullscreen
                    />
                }
                {
                    <iframe
                        title="Promotional video"
                        className='w-[90%] h-[280px] md:h-[360px] md:w-[500px] lg:h-[280px] lg:w-[450px] rounded-3xl relative top-[80px] md:top-[150px]'
                        id="ytplayer"
                        type="text/html"
                        src={`https://www.youtube.com/embed/${linkVideo3}?&autoplay=1&loop=1&rel=0&showinfo=0&color=white&iv_load_policy=3&playlist=${linkVideo3}`}
                        frameborder="0" allowfullscreen
                    />
                }
            </div>
        </div>
    )
}

export default VideoPromocional2